
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  --basfblue: #004a96;
  --basfgray: #ddd;
  --lightblue: #1977cc;
  --lightgreen: rgb(140, 237, 140);
  --even: #eee;
  --dark: #444444;
}
body {
  font-family: "Montserrat", sans-serif;
    color: var(--dark);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/material_icons_rounded.woff2') format('woff2');
}
@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('woff2');
}
@font-face {
  font-family: 'Montserrat Italic';
  src: url('./fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('woff2');
}

.material-symbols-rounded {
  font-family: 'Material Symbols Rounded', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}
.shift-down{
  position: relative;
  top: 6px;
}
.shift-down-little{
  position: relative;
  top: 4px;
}
.shift-down-mini{
  position: relative;
  top: 1px;
}
.button-icon{
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-left: -5px;
}
.button-icon-right{
  font-size: 18px;
  position: relative;
  top: 3px;
  margin-right: -5px;
}
.text-little{
  font-size: 0.7rem;
  position: relative;
  bottom: 0.1rem;
}
.invisible-over-page{
  position: absolute;
  top: -25%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center-in-page{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: top 1s ease;
  border-color: white;
}
.bg-gray{
  background-color: var(--basfgray);
}
.bg-lightgray{
  background-color: var(--even);
}
@media only screen and (min-width: 425px){
  .d-none-big{
    display: none;
  }
}
@media only screen and (max-width: 640px){
  .d-none-small{
    display: none;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
header{
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 90px;
  background-color: var(--basfblue);
}
.header-text{
  color: var(--even);
  font-size: 20px;
}
@media only screen and (max-width: 767px){
  .header-text{
    display: none;
  }
}
@media only screen and (max-width: 991px){
  .header-text{
    font-size: 16px;
  }
}
.header-icon{
  color: var(--even);
}
@media only screen and (max-width: 767px){
  .header-icon{
    display: none;
  }
}
.logo_img{
  height: 60px;
}
@media only screen and (max-width: 767px){
  .logo_img{
    height: 40px;
  }
}
nav{
  width: 180px;
}
@media only screen and (max-width: 767px){
  nav{
    width: 120px;
  } 
}
@media only screen and (max-width: 991px){
  nav{
    width: 150px;
  } 
}
.flag-active {
  height: 36px !important;
  border: 3px solid var(--basfgray);
}
.menu-flag{
  height: 30px;
}
@media only screen and (max-width: 991px){
  .flag-active {
    height: 30px !important;
  }
  .menu-flag{
    height: 24px;
  }
  @media only screen and (max-width: 767px){
    header h2 {
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 991px){
    header h2 {
      font-size: 20px;
    }
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  color: var(--dark);
  font-size: 14px;
  background: var(--basfgray);
}
#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #fff;
  box-shadow: 0 2px 15px rgba(25, 119, 204, 0.1);
}
#footer .copyright img{
  height: 25px;
}

/*--------------------------------------------------------------
# Hero (central space of page)
--------------------------------------------------------------*/
.hero {
  position: relative;
  margin-top: 140px;
  width: 100%;
  min-height: calc(100vh - 197px);
  background-size: cover;
}
@media only screen and (max-width: 767px){
  .hero{
    min-height: calc(100vh - 218px);
  }
}
.corner-right {
  position: absolute;
  top: 95px;
  right: 0;

}
.login-form {
  background-color: var(--basfgray);
}
.answer{
  width: 99%;
  margin: auto;
  cursor: pointer;
  background-color: white;
  padding: 20px 5px;
  border: 1px solid var(--basfgray);
  border-radius: 5px;
}
.selected {
  background-color: var(--lightgreen)
}
/**
* Video *
*/
.video-player {
  position: relative;
}
.play-button {
  cursor: pointer;
  position: absolute;
  height: 30%;
  top: 45%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
/**
* Buttons *
*/
.basf-btn {
  margin-left: 25px;
  background: var(--lightblue);
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  white-space: nowrap;
  transition: 0.3s;
  font-size: 14px;
  display: inline-block;
  border: none;
}
.inverted {
  color: var(--lightblue);
  background: #fff;
}
.inverted:hover {
  color: var(--basfblue) !important;
  background: lightgray !important;
}
.small-btn {
  font-size: 12px;
  padding: 4px 12.5px;
  margin-left: 10px;
}
.basf-btn:disabled {
  background-color: var(--basfgray);
  border-color: darkgrey;
  color: var(--dark);
}
.basf-btn:hover {
  background: var(--basfblue);
  color: #fff;
  border: 1px solid #fff
}
.basf-btn:disabled:hover {
  background-color: var(--basfgray);
  border-color: darkgrey;
  color: var(--dark);
  border: none;
}
.btn_length_it{
  width: 160px;
}
.btn_length_en{
  width: 160px;
}
.btn_length_de{
  width: 290px;
}
@media (max-width: 767px) {
  .basf-btn {
      margin: 0 15px 0 0;
      padding: 6px 18px;
  }
  .small-btn {
    font-size: 14px;
    padding: 4px 12.5px;
    margin-left: 10px;
  }
}
.invisible-btn{
  appearance: none;
  border: none;
  background: transparent;
}
/**
* Inputs and forms *
*/
.rounded-left{
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}